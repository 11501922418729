import React, { useContext, useMemo } from 'react';

import { LocaleContext } from '@ninox/ninox-components/locale';

import { ProtectedOutlet } from '../../../session/ProtectedOutlet';
import { TabNav } from '../../../shared/TabNav';

export const Layout = () => {
    const locale = useContext(LocaleContext);

    const tabRoutes = useMemo(
        () => [
            {
                value: '',
                end: true,
                label: locale.integrationsApiKeys,
                trackKey: 'APIKeys',
                description: locale.integrationsApiSummary
            },
            {
                value: 'tools',
                label: locale.integrationsTools,
                trackKey: 'ConnectedApps',
                description: locale.integrationsToolsSummary
            }
        ],
        [locale]
    );

    return (
        <TabNav routes={tabRoutes}>
            <ProtectedOutlet />
        </TabNav>
    );
};
